import React from "react"
import { graphql, Link } from "gatsby"
import Seo from "../components/SEO"
import { LionsInfoSingleStyles } from "../components/LionsInfo/LionsInfoStyles"
import Button from "../components/Button/Button"
import ContentfulRichText from "../components/ContentfulRichText"

const LionsInfo = ({ data }) => {
  const { title, published, richText, introduction, slug } = data.lionsinfo
  const storyKeywords = title
  return (
    <>      
      <Seo pageTitle={title} 
          pageDescription={introduction} 
          canonicalUri={`/lionsinfo/${slug}`}
          pageKeywords = {storyKeywords}         
      />
      <section>
        <LionsInfoSingleStyles>
          <h1 className="lionsinfo-single__title">{title}</h1>
          <p className="lionsinfo-single_date">{published}</p>
          <article className="lionsinfo-single__content">
            <ContentfulRichText richText={richText} />
            <div className="lionsinfo-single__back">
              <Button to="/lionsinfo" text="Back to About Lions" as={Link} />
            </div>
          </article>
        </LionsInfoSingleStyles>
      </section>
    </>
  )
}

export const query = graphql`
  query getLionsInfo($slug: String!) {
    lionsinfo: contentfulLionsInfo(slug: { eq: $slug }) {
      slug
      contentful_id
      title
      introduction
      published(formatString: "MMMM Do YYYY")
      richText {
        raw
        references {
          contentful_id
          title
          description
          gatsbyImageData(width: 1000, formats: [AUTO, WEBP])
          __typename
          url
          mimeType
        }  
      }
    }
  }
`


export default LionsInfo
